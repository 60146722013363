<template>
  <v-container>
    <div id="FilterContainer" class="filter-container">
      <div class="filter-item">
        <div class="form-control-container">
          <div class="form-control-container__time">StartYear</div>
          <v-select
            ref="yearChart"
            hint="Anfangsjahr"
            persistent-hint
            v-model="yearStartSelection"
            @change="offerGraph"
            :items="generateYearsOptions()"
          >
          </v-select>
        </div>
      </div>
      <div class="filter-item">
        <div class="form-control-container">
          <div class="form-control-container__time">EndYear</div>
          <v-select
            ref="yearChart"
            hint="Endjahr"
            persistent-hint
            :items="generateYearsOptions()"
            v-model="yearEndSelection"
            @change="offerGraph"
          >
          </v-select>
        </div>
      </div>
    </div>
    <div id="FilterContainer" class="filter-container">
      <div class="filter-item">
        <div class="form-control-container">
          <v-text-field
            label="Netto Wert Minimum"
            placeholder="nur Zahlen"
            type="number"
            v-model="min_netto"
            min="0"
            @change="offerGraph"
          ></v-text-field>
        </div>
      </div>
      <div class="filter-item">
        <div class="form-control-container">
          <v-text-field
            label="Netto Wert Maximum"
            placeholder="nur Zahlen"
            type="number"
            v-model="max_netto"
            min="0"
            @change="offerGraph"
          ></v-text-field>
        </div>
      </div>
      <div class="filter-item">
        <div class="form-control-container">
          <v-text-field
            label="Anzahl Minimum"
            placeholder="nur Zahlen"
            type="number"
            v-model="minBetragSelection"
            min="0"
            @change="offerGraph"
          ></v-text-field>
        </div>
      </div>
      <div class="filter-item">
        <div class="form-control-container">
          <v-text-field
            label="Anzahl Maximum"
            placeholder="nur Zahlen"
            type="number"
            v-model="maxBetragSelection"
            min="0"
            @change="offerGraph"
          ></v-text-field>
        </div>
      </div>
    </div>
    <br />

    <div class="outer-container rounded">
      <h3>Artikelfilter</h3>
      <br />
      <v-row>
        <!--Combobox zum Auswählen der Artikeltypen-->
        <v-autocomplete
          filled
          class="mx-sm-4 mx-lg-3"
          :items="articleTypes"
          item-text="description"
          v-model="art_type"
          return-object
          label="Artikeltype"
          clearable
        ></v-autocomplete>
      </v-row>
      <v-row>
        <v-col>
          <!--Combobox zum Auswählen der Artikategorie-->
          <v-autocomplete
            :items="articleCategories"
            item-text="description"
            v-model="art_kategorie"
            return-object
            label="Artikelkategorie"
            clearable
            filled
          ></v-autocomplete>
        </v-col>
        <v-col>
          <!--Combobox zum Auswählen der Artikelgruppen-->
          <v-autocomplete
            filled
            :items="articleGroups"
            item-text="description"
            v-model="art_gruppe"
            return-object
            label="Artikelgruppe"
            clearable
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-btn @click="addArticle">Anwenden</v-btn><br /><br />
      <v-chip
        clearable
        v-for="(item, index) in filterArticleName"
        v-bind:item="item"
        v-bind:index="index"
        v-bind:key="item.id"
        @click:close="articleClose(index)"
        closable
        close
      >
      </v-chip>
    </div>
    <div class="d-flex justify-end">
      <v-btn icon color="black" @click="yearBack">
        <v-icon>mdi-arrow-left-thick</v-icon>
      </v-btn>
      <v-btn icon color="black" @click="yearForward">
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
    </div>
    <div id="chartContainer">
      <div id="graph1">
        <h3>Angebote</h3>

        <apexchart
          ref="totalChart1"
          type="bar"
          height="350"
          :options="offerGraphOptions"
          :series="offerGraphSeries"
        ></apexchart>
      </div>
      <div id="graph2">
        <h3>Angebote Umsatz / DB</h3>
        <apexchart
          ref="totalChart2"
          type="bar"
          height="350"
          :options="offerGraph2Options"
          :series="offerGraph2Series"
        ></apexchart>
      </div>
    </div>
  </v-container>
</template>
<script>
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js'
import { mapState, mapGetters } from 'vuex'

export default {
  created() {
    this.offerGraph()
    this.generateYearsOptions()
    this.loadData()
  },
  beforeMount() {
    this.loadData()
    this.offerGraph()
  },
  mounted() {
    this.loadData()
    this.offerGraph()
  },
  computed: {
    ...mapState({
      //filtersActive: state => state.crmFilter.filterActivated,
      articleTypes: state => state.articleFilter.articleTypes,
      articleCategories: state => state.articleFilter.articleCategories,
      articleGroups: state => state.articleFilter.articleGroups
      //filter: state => state.articleFilter.filter,
      //visibleTabs: state => state.articlePermissions.visibleTabs
      //foundArticles: state => state.articleFilter.foundArticles
    }),
    ...mapGetters(['getFlter'])
  },

  data: function() {
    return {
      CustomerDetailsYear: new Date().getFullYear() - 1,
      yearStartSelection: new Date().getFullYear() - 4,
      yearEndSelection: new Date().getFullYear(),

      offerAll: [],
      offerOpen: [],
      offerDB: [],
      offerUM: [],

      tDB: [],
      tUM: [],

      art_kategorie: '',
      art_type: '',
      art_gruppe: '',

      filterArticle: [],
      filterArticleName: [],
      offerGraph2Series: {},
      offerGraphSeries: {},

      offerGraphOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex)
            }
          }
        },
        dataLabels: {
          enabled: true
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },
      offerGraph2Options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex)
            }
          }
        },
        dataLabels: {
          enabled: false
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              return formatter.format(value).replace(/\s/g, '')
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      }
    }
  },
  methods: {
    articleClose(index) {
      this.filterArticle.splice(index, 1)
      this.filterArticleName.splice(index, 1)
      this.offerGraph()
    },
    offerGraph() {
      customerDevelopmentService
        .getOffers(
          this.yearStartSelection,
          this.yearEndSelection,
          this.min_netto,
          this.max_netto,
          this.minBetragSelection,
          this.maxBetragSelection,
          this.filterArticle
        )
        .then(response => {
          response.data.forEach(data => {
            this.offerAll.push(data.tCount)
            this.offerOpen.push(data.oCount)
            this.offerDB.push(data.oDb)
            this.offerUM.push(data.oUm)
            this.tDB.push(data.tDb)
            this.tUM.push(data.tUm)
          })

          this.updateChart1()
          this.updateChart2()
        })
        .catch(error => {
          console.error(error)
        })
    },
    updateChart1() {
      const startYear = this.yearStartSelection
      const endYear = this.yearEndSelection
      const xAxisCategories = []

      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString())
      }

      this.offerGraphOptions.xaxis.categories = xAxisCategories

      this.$refs.totalChart1.updateSeries([
        {
          name: 'Anzahl',
          data: this.offerAll
        },
        {
          name: 'Offen',
          data: this.offerOpen
        }
      ]),
        this.$refs.totalChart1.updateOptions({
          xaxis: {
            categories: xAxisCategories
          }
        })
      this.offerAll = []
      this.offerOpen = []
    },
    updateChart2() {
      const startYear = this.yearStartSelection
      const endYear = this.yearEndSelection
      const xAxisCategories = []

      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString())
      }

      this.$refs.totalChart2.updateSeries([
        {
          name: 'Offener Deckungsbetrag',
          group: 'Gesamt',
          data: this.offerDB
        },
        {
          name: 'Offener Umsatz',
          data: this.offerUM
        },
        {
          name: 'Gesamter Deckungsbetrag',
          data: this.tDB
        },
        {
          name: 'Gesamter Umsatz',
          data: this.tUM
        }
      ]),
        this.$refs.totalChart2.updateOptions({
          xaxis: {
            categories: xAxisCategories
          }
        })
      this.tDB = []
      this.tUM = []
      this.offerDB = []
      this.offerUM = []
    },
    addArticle() {
      if (
        (this.art_kategorie == '' || this.art_kategorie == null) &&
        (this.art_type == '' || this.art_type == null) &&
        (this.art_gruppe == '' || this.art_gruppe == null)
      ) {
        return
      }

      let artkat = ''
      let arttyp = ''
      let artgrp = ''
      let artkatn = ''
      let arttypn = ''
      let artgrpn = ''

      if (this.art_kategorie != '' && this.art_kategorie != null) {
        artkat = this.art_kategorie.key.lfdnr
        artkatn = this.art_kategorie.description
      }
      if (this.art_type != '' && this.art_type != null) {
        arttyp = this.art_type.key.tpCode
        arttypn = this.art_type.description
      }
      if (this.art_gruppe != '' && this.art_gruppe != null) {
        artgrp = this.art_gruppe.key.lfdnr
        artgrpn = this.art_gruppe.description
      }

      this.filterArticle.push({
        art_kategorie: artkat,
        art_type: arttyp,
        art_gruppe: artgrp
      })
      this.filterArticleName.push({
        art_kategorie: artkatn,
        art_type: arttypn,
        art_gruppe: artgrpn
      })

      this.art_gruppe = ''
      this.art_type = ''
      this.art_kategorie = ''
      this.offerGraph()
    },
    generateYearsOptions() {
      let currentYear = new Date().getFullYear()
      let options = []

      for (let i = currentYear; i >= 2010; i--) {
        options.push({ text: i.toString(), value: i })
      }
      return options
    },

    yearBack() {
      let yearStart = this.yearStartSelection
      if (yearStart != this.getMinYear) {
        if (this.getSelectedYear > this.yearEndSelection - 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear - 1)
        }
        this.yearStartSelection = yearStart - 1
        this.yearEndSelection -= 1
        this.offerGraph()
      }
    },
    yearForward() {
      let yearEnd = this.yearEndSelection
      if (yearEnd != this.getCurrentYear) {
        if (this.getSelectedYear < this.yearStartSelection + 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear + 1)
        }

        this.yearStartSelection += 1
        this.yearEndSelection = yearEnd + 1
        this.offerGraph()
      }
    },
    loadData() {
      this.$store.dispatch('loadArticleTypes')
      this.$store.dispatch('loadArticleCategories')
      this.$store.dispatch('loadArticleGroups')
      //this.$store.dispatch('loadVisibleTabs')

      const storedFilter = this.$store.getters.getFlter
      this.articleType = storedFilter.articleType
      this.articleCategory = storedFilter.articleCategory
      this.articleGroup = storedFilter.articleGroup
    }
  }
}
</script>
<style>
#chartContainer {
  display: flex;
}

#graph1 {
  flex: 1;
}
#graph2 {
  flex: 1;
}

#FilterContainer {
  display: flex;
}
#StartYear {
  flex: 1;
}
#EndYear {
  flex: 1;
}
#form_control {
  flex: 1;
}

#form_control_container {
  flex: 1;
}

#FilterContainer {
  display: flex;
  justify-content: space-between;
}

.filter-item {
  flex: 1;
}

.form-control-container {
  padding: 10px;
}

.form-control-container__time {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-control-container__time__input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
}

.outer-container {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
}
.v-chip {
  margin-right: 8px;
  margin-bottom: 8px;
}
</style>
