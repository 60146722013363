<template>
  <v-container>
    <div>
      <div class="header">
        <h2>
          {{ headLineText }}
          <span class="subtitle-1"
            >{{ this.yearStartSelection }}-{{ this.yearEndSelection }}</span
          >
        </h2>
      </div>
      <h3>
        <span class="subtitle-2"></span>
      </h3>

      <v-expansion-panels class="py-4 mx-auto" v-model="filterPanel">
        <v-expansion-panel>
          <v-expansion-panel-header class="py-0" expand-icon="mdi-menu-down">
            <v-container>
              <v-layout row wrap class="d-flex align-center">
                <span class="mr-2 pt-1"
                  ><v-icon>mdi-filter-outline</v-icon>Filter</span
                >
              </v-layout>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="filter-content" v--if="!isCollapsed">
              <div id="yearContainer">
                <div id="StartYear">
                  <v-select
                    ref="yearChart"
                    class="me-3 fit"
                    hint="Anfangsjahr"
                    persistent-hint
                    v-model="yearStartSelection"
                    @change="test"
                    :items="generateYearsOptions()"
                  >
                  </v-select>
                </div>

                <div id="EndYear">
                  <v-select
                    ref="yearChart"
                    class="mx-3 fit"
                    hint="Endjahr"
                    persistent-hint
                    :items="generateYearsOptions()"
                    v-model="yearEndSelection"
                    @change="test"
                  ></v-select>
                </div>
              </div>
              <div id="addressType">
                <!--{{ selectedAddressType }}-->
                <v-select
                  class="mb-5 mt-3"
                  :items="addressTypesItems"
                  item-text="name"
                  item-value="id"
                  label="Adressart"
                  chips
                  clearable
                  deletable-chips
                  v-model="selectedAddressType"
                  multiple
                  @change="test"
                  ><v-chip close></v-chip>
                </v-select>
              </div>
              <div id="CustomerGroup">
                <!--{{ selectedGroup }}-->
                <v-select
                  class="mb-5 mt-3"
                  :items="customerGroupsArray"
                  item-text="name"
                  item-value="id"
                  label="Lieferantengruppe"
                  v-model="selectedGroup"
                  multiple
                  chips
                  clearable
                  deletable-chips
                  @change="test"
                >
                </v-select>
              </div>
              <div id="Branches">
                <!--{{ selectedGroup }}-->
                <v-select
                  class="mb-5 mt-3"
                  :items="shops"
                  item-text="name"
                  item-value="id"
                  label="Filialen"
                  v-model="selectBranche"
                  multiple
                  chips
                  clearable
                  deletable-chips
                  @change="test"
                >
                </v-select>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <br />
      <div id="graph12">
        <div id="graph1">
          <h3>Lieferanten im Jahresvergleich</h3>
          <div class="d-flex justify-end">
            <v-btn icon color="black" @click="yearBack1">
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>

            <v-btn icon color="black" @click="yearForward1">
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </div>
          <div id="chart1">
            <apexchart
              ref="totalChart"
              type="bar"
              height="350"
              :options="totalChartOptions"
              :series="total"
            ></apexchart>
          </div>
        </div>
        <div id="graph2">
          <h3>Deckungsbetrag / Umsatz pro Lieferant</h3>
          <div class="d-flex justify-end">
            <v-btn icon color="black" @click="yearBack2">
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>
            <v-btn icon color="black" @click="yearForward2">
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </div>
          <div id="chart2">
            <apexchart
              ref="totalChart2"
              type="bar"
              height="350"
              :options="customerDbRevenueOptions"
              :series="customerDbRevenueSeries"
            ></apexchart>
          </div>
        </div>
      </div>
      <div id="graph3">
        <div
          class="d-flex"
          style="display: flex; justify-content: space-between; align-items: center;"
        >
          <h3 style="margin: 0;">
            10 stärkste Lieferanten
            <span class="subtitle-1"
              >{{ this.yearStartSelection }}-{{ this.yearEndSelection }}</span
            >
          </h3>

          <div style="margin-left: auto;">
            <v-switch
              @change="changeswitch"
              label="Lieferanten invertieren"
            ></v-switch>
          </div>
        </div>
        <div class="d-flex justify-end">
          <v-btn
            icon
            :disabled="checkifYEARisset()"
            color="black"
            @click="
              strongestCustomersYear -= 1
              strongestCustomers()
            "
          >
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          <v-btn
            icon
            :disabled="checkifYEARisset()"
            color="black"
            @click="
              strongestCustomersYear += 1
              strongestCustomers()
            "
          >
            <v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn>
        </div>
        <div id="chart3">
          <apexchart
            ref="totalChart3"
            type="bar"
            height="350"
            :options="strongestCustomersOptions"
            :series="strongestCustomersSeries"
          >
          </apexchart>
        </div>
      </div>
      <div id="graph4">
        <h3>Artikelbewegungen</h3>
        <v-select
          class="mb-5 mt-3"
          :items="this.AllSupplierList"
          item-text="name"
          item-value="lfdnr"
          label="LieferantenDetails"
          v-model="selectedCustomer"
          @change="test"
        ></v-select>
        <div class="d-flex justify-end">
          <v-btn
            icon
            color="black"
            @click="
              CustomerDetailsYear -= 1
              CustomerDetails()
            "
          >
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
          <v-btn
            icon
            color="black"
            @click="
              CustomerDetailsYear += 1
              CustomerDetails()
            "
          >
            <v-icon>mdi-arrow-right-thick</v-icon>
          </v-btn>
        </div>
        <div class="tab">
          <button class="tablinks" @click="TabSelect($event, 'artikeltypen')">
            Artikeltypen
          </button>
          <button
            class="tablinks"
            @click="TabSelect($event, 'artikelkategorie')"
          >
            Artikelkategorie
          </button>
          <button class="tablinks" @click="TabSelect($event, 'artikelgruppe')">
            Artikelgruppe
          </button>
          <button
            class="tablinks"
            @click="TabSelect($event, 'statistikgruppen')"
          >
            Statistikgruppe
          </button>
          <button class="tablinks" @click="TabSelect($event, 'erloesgruppen')">
            Aufwandsgruppe
          </button>
          <button class="tablinks" @click="TabSelect($event, 'statistikcode')">
            Statistikcode
          </button>
        </div>
        <div v-bind:id="this.selectedTab">
          <apexchart
            ref="totalChart4"
            type="bar"
            height="500"
            :options="customerDetailsOptions"
            :series="customerDetailsSeries"
          ></apexchart>
        </div>
      </div>

      <div id="CustomerDocuments">
        <v-text-field
          class="my-5"
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          outlined
        ></v-text-field>
        <v-data-table
          :headers="header2"
          :items="CustomerDocumentsItems"
          :items-per-page="10"
          :search="search"
          item-key="kdnr"
          class="elevation-1"
          @click:row="handleRowClick"
        ></v-data-table>
      </div>
      <div id="CustomerDocuments">
        <v-text-field
          class="my-5"
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          outlined
        ></v-text-field>
        <v-data-table
          :headers="header"
          :items="CustomerDocumentsItems2"
          :items-per-page="10"
          :search="search"
          item-key="kdnr"
          class="elevation-1"
        ></v-data-table>
      </div>
    </div>
  </v-container>
</template>

<script>
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js'
import customerDatatable from '@/components/statistics/customerDevelopment/customerDatatable.vue'
import customersGroups from '@/components/statistics/customerDevelopment/customersGroups.vue'
import showStores from '@/components/statistics/customerDevelopment/storeDevelopment.vue'
import offerService from '@/services/statistics/offerService.js'
import { mapGetters } from 'vuex'

export default {
  mounted() {
    this.customerDbRevenue()

    this.selectedTotalCustomerDevelopmentOfFiveYear()
    //this.strongestCustomers()
    showStores
    customerDatatable
    customersGroups
    this.showYears.call(this)
    this.totalDevelopmentNew(this)
  },

  props: {
    //highestYearInFiveShownYears: { type: Number, required: true },
    //newCustomer: { type: Array, required: true },
    totalCustomer: { type: Array, required: true }
    //activeCustomer: { type: Array, required: true },
    //inactiveCustomer: { type: Array, required: true }
    //headLineText: { type: String, required: true }
  },
  computed: {
    ...mapGetters([
      'getYearStart',
      'getYearEnd',
      'getCustomerGroup',
      'getLocations',
      'getMinYear',
      'getMinYear',
      'getSelectedYear'
    ])
  },

  data: function() {
    return {
      YEAR: 0,
      users: [],
      CustomerDocumentsItems2: [],
      headLineText: 'Gesamtentwicklung der Lieferanten',
      headLineTextKunden: '10 stärkste Kunden',
      addressTypesItems: [],
      customerGroupsArray: [],
      selectedAddressType: [],
      yearEndSelection: new Date().getFullYear(),
      yearEndSelectionArticleType: new Date().getFullYear(),
      selectedGroup: [],
      shops: [],
      switchstatus: false,
      getYearsItems: [],
      search: '',
      yearStartSelection: new Date().getFullYear() - 4,
      yearStartSelectionArticleType: new Date().getFullYear() - 4,
      selected: [],
      testItems: [],
      selectedTest: [],
      selectBranche: [],
      totalyearcustomer: '',
      //activeCustomer2: [],
      //inactiveCustomer2: [],
      GraphYearYears: [],
      //newCustomer2: [],
      totalCustomer2: [],
      showMonthGraph: false,
      selectedYear: null,
      currentYear: null,
      db: [],
      revenue: [],
      strongestCustomers2: [],
      xAxisCategories: [],
      xAxisCategoriesCustomerDetails: [],
      seriesData: [],
      seriesDataCustomerDetails: [],
      dataforYear: [],
      selectedCustomer: '',
      selectedTab: 'artikeltypen',
      customerId: '',
      AllCustomerId: [],
      AllCustomers: [],
      strongestCustomersYear: new Date().getFullYear() - 1,
      CustomerDetailsYear: new Date().getFullYear() - 1,
      AllCustomersList: [],
      AllSupplierList: [],
      selectedCustomerName: null,
      tmp: [],
      CustomerDetailsSelectedYear: '',
      isCollapsed: false,
      idfromxAxisCustomerDetails: [],
      idfromxAxisCustomerDetails2: [],
      dataPointIndexGraph: '',
      subkdnrCustomerDetails: [],
      fibu_kto: '',
      articleindex: [],
      type: '',
      code: '',
      art_gruppe: '',
      art_kategorie: '',
      art_type: '',
      art_statcode: '',
      CustomerDocumentsItems: [],

      header: [
        {
          text: 'Dokument',
          value: 'document',
          align: 'start',
          sortable: true
        },
        {
          text: 'Datum',
          value: 'date',
          align: 'start',
          sortable: true
        },
        {
          text: 'L-Termin',
          value: 'ldate',
          align: 'start',
          sortable: true
        },
        {
          text: 'Dok.-Nr.',
          value: 'docNR',
          align: 'start',
          sortable: true
        },
        {
          text: 'Position',
          value: 'position',
          align: 'start',
          sortable: false
        },
        {
          text: 'Artikel-SM',
          value: 'articleSM',
          align: 'start',
          sortable: false
        },
        {
          text: 'Saison',
          value: 'saison',
          align: 'start',
          sortable: false
        },
        {
          text: 'Lokation',
          value: 'location',
          align: 'start',
          sortable: true
        },
        {
          text: 'L-Menge',
          value: 'lQuantity',
          align: 'start',
          sortable: true
        },
        {
          text: 'Rg-Menge',
          value: 'rqQuantity',
          align: 'start',
          sortable: true
        },
        {
          text: 'ME',
          value: 'me',
          align: 'start',
          sortable: false
        },
        {
          text: 'Nettopreis',
          value: 'netprice',
          align: 'start',
          sortable: true
        },
        {
          text: 'Eigenanteil',
          value: 'ownContribution',
          align: 'start',
          sortable: true
        }
      ],
      header2: [
        {
          text: 'Name',
          value: 'article.artBezeichung',
          align: 'start',
          sortable: true
        },
        {
          text: 'Nummer',
          value: 'article.artNr',
          align: 'start',
          sortable: true
        },
        {
          text: 'Umsatz',
          value: 'pos_netto',
          align: 'start',
          sortable: true
        },
        {
          text: 'DB',
          value: 'db',
          align: 'start',
          sortable: true
        },
        {
          text: 'Menge',
          value: 'menge',
          align: 'start',
          sortable: false
        }
      ],
      customerDetailsOptions: {
        chart: {
          height: 500,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: async (event, chartContext, config) => {
              const { seriesIndex, dataPointIndex } = config
              this.CustomerDetails()
              if (this.tmp && this.tmp[seriesIndex]) {
                this.CustomerDetailsSelectedYear = this.tmp[seriesIndex].name
                console.log(
                  'Selected Series Name:',
                  this.CustomerDetailsSelectedYear
                )
                console.log(dataPointIndex + 'adasd')
              } else {
                console.log('Series Name not available or undefined.')
              }
              this.dataPointIndexGraph = dataPointIndex
              const xAxisValue = this.xAxisCategoriesCustomerDetails[
                seriesIndex
              ]
              console.log('Selected X-Axis Value:', xAxisValue)

              this.type = await this.test1(config.dataPointIndex)

              switch (this.selectedTab) {
                case 'artikelgruppe':
                  this.CustomerDocumentsbyGroup()
                  break
                case 'artikeltypen':
                  this.CustomerDocuments()
                  break
                case 'artikelkategorie':
                  this.CustomerDocumentsbyCategorie()
                  break
                case 'statistikgruppen':
                  this.CustomerDocumentsbystatGroup()
                  break
                case 'erloesgruppen':
                  this.CustomerDocumentsbyErloesgroup()
                  break
                case 'statistikcode':
                  this.CustomerDocumentsbystatCode()
                  break
              }

              this.CustomerDocuments()
            }
          }
        },
        dataLabels: {
          enabled: false
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              return formatter.format(value).replace(/\s/g, '')
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },

      strongestCustomersOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: async (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex)
              console.log(config.dataPointIndex)
              console.log(this.xAxisCategoriesCustomerDetails)

              var customer = await this.getCustomerbyIndex(
                config.dataPointIndex
              )
              this.selectedCustomer = customer
              console.log(this.selectedCustomer)
              this.CustomerDetails()
            }
          }
        },

        dataLabels: {
          enabled: false
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        yaxis: {
          type: 'logarithmic',
          labels: {
            formatter: function(value) {
              const originalValue = Math.pow(10, value)

              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              const formattedValue = formatter.format(originalValue)
              return formattedValue
            }
          }
        },
        xaxis: {
          categories: []
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },

      customerDbRevenueOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex)
              var selectedBarIndex = config.dataPointIndex
              if (selectedBarIndex == -1) {
                console.log()
              } else {
                var year = this.yearStartSelection + selectedBarIndex
              }

              if (this.YEAR == year) {
                this.YEAR = 0
              } else {
                this.YEAR = year
              }
              this.strongestCustomers()

              //this.$emit('showNewCustomers', config.dataPointIndex)
            }
          }
        },
        dataLabels: {
          enabled: false
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              return formatter.format(value).replace(/\s/g, '')
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },

      totalChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              var selectedBarIndex = config.dataPointIndex
              if (selectedBarIndex == -1) {
                console.log()
              } else {
                var year = this.yearStartSelection + selectedBarIndex
              }

              if (this.YEAR == year) {
                this.YEAR = 0
              } else {
                this.YEAR = year
              }
              this.strongestCustomers()

              //this.$emit('showNewCustomers', config.dataPointIndex)
            }
          }
        },

        dataLabels: {
          enabled: true
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      }
    }
  },
  created() {
    this.CustomerDetails()
    //this.CustomerDocuments()
    this.strongestCustomers()
    this.showAddressTypes()
    this.showCustomerGroups()
    this.showStores(this)

    this.showYears()

    this.AllCustomersList = this.ListOfAllPersons('consumer')
    this.AllSupplierList = this.ListOfAllPersons('supplier')

    this.generateYearsOptions()
    this.selectedTotalCustomerDevelopmentOfFiveYear()
    this.customerDbRevenue()

    this.selectedTotalCustomerDevelopmentOfYear()
  },
  beforeMount() {
    this.customerDbRevenue()
    this.CustomerDetails()

    this.selectedTotalCustomerDevelopmentOfFiveYear()
    this.showStores()

    this.showYears()
    this.AllCustomersList = this.ListOfAllPersons('consumer')
    this.AllSupplierList = this.ListOfAllPersons('supplier')
    this.selectedTotalCustomerDevelopmentOfYear()
  },

  methods: {
    handleRowClick(item) {
      const index = this.CustomerDocumentsItems2.findIndex(
        element => element === item
      )
      console.log('Index des ausgewählten Elements:', index)
      console.log(item.article.artNr)
      this.art_nr = item.article.artNr
      this.CustomerDocuments2()
      // console.log("bin i's gleiche? " + this.art_nr)
      //this.getArticlesbyType2()
    },

    async test1(index) {
      return this.articleindex[index]

      // this.getArticlesbyType()
    },

    getUsersArray() {},

    checkifYEARisset() {
      if (this.YEAR == 0) {
        return false
      } else return true
    },

    changeswitch() {
      if (this.switchstatus == false) {
        this.switchstatus = true
      } else {
        this.switchstatus = false
      }
      this.strongestCustomers()
      console.log(this.switchstatus)
    },

    toggleFilters() {
      this.isCollapsed = !this.isCollapsed
    },
    test() {
      this.selectedTotalCustomerDevelopmentOfFiveYear()
      this.CustomerDetails()
      this.strongestCustomers()
      this.customerDbRevenue()
      this.selectedAddressTypes()
      this.selectedCustomerGroups()
      this.selectedBranches()
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1)
    },
    TabSelect(evt, name) {
      this.selectedTab = name
      this.CustomerDetails()

      const tabcontent = document.getElementsByClassName('tabcontent')
      for (let i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = 'none'
      }

      const tablinks = document.getElementsByClassName('tablinks')
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(' active', '')
      }

      document.getElementById(name).style.display = 'block'
      evt.currentTarget.className += ' active'
    },

    showCustomerGroups() {
      customerDevelopmentService.getListOfAllCustomerGroups().then(response => {
        response.data.forEach(customerGroup => {
          this.customerGroupsArray.push(customerGroup)
        })
      })
    },

    generateYearsOptions() {
      let currentYear = new Date().getFullYear()
      let options = []

      for (let i = currentYear; i >= 2010; i--) {
        options.push({ text: i.toString(), value: i })
      }
      return options
    },
    showAddressTypes() {
      customerDevelopmentService.getListOfAllAddressTypes().then(response => {
        response.data.forEach(customerGroup => {
          this.addressTypesItems.push(customerGroup)
        })
      })
    },
    showStores() {
      customerDevelopmentService.getDetaillisteOfAllStores().then(response => {
        this.shops = response.data.map(items => ({
          id: items.id,
          name: items.description
        }))
      })
    },
    showYears() {
      offerService.getOffersForYearSpan().then(response => {
        response.data.forEach(year => {
          this.getYearsItems.push(year)
        })
      })
    },
    CustomerDocuments() {
      this.CustomerDocumentsItems = []
      console.log('423')
      console.log(this.idfromxAxisCustomerDetails)
      customerDevelopmentService
        .getArticlesbyType(
          this.type
          /*this.art_kategorie,
          this.selectedCustomer,
          this.CustomerDetailsSelectedYear,
          this.CustomerDetailsSelectedYear,
          this.fibu_kto,
          this.code,
          this.art_gruppe,
          this.art_type,
          this.art_statcode*/
        )
        .then(response => {
          this.CustomerDocumentsItems = response.data
          console.log(response.data)
          this.CustomerDetails()
        })
        .catch(error => {
          console.error(
            'Fehler bei der Abfrage der Lieferantendokumente:',
            error
          )
        })
    },

    CustomerDocuments2() {
      this.CustomerDocumentsItems2 = []

      console.log(this.idfromxAxisCustomerDetails)
      customerDevelopmentService
        .getArticlesbyType3(
          this.art_kategorie,
          this.art_nr,
          '1900',
          '2090'
          /*
          this.selectedCustomer,
          this.CustomerDetailsSelectedYear,
          this.CustomerDetailsSelectedYear,
          this.fibu_kto,
          this.code,
          this.art_gruppe,
          this.art_type,
          this.art_statcode*/
        )
        .then(response => {
          this.CustomerDocumentsItems2 = response.data
          console.log(response.data)
          this.CustomerDetails()
        })
        .catch(error => {
          console.error('Fehler bei der Abfrage der Kundendokumente:', error)
        })
    },

    CustomerDocumentsbyCategorie() {
      this.CustomerDocumentsItems = []
      console.log('423')
      console.log(this.idfromxAxisCustomerDetails)
      customerDevelopmentService
        .getArticlesbyCategorie(
          this.art_kategorie
          /*this.art_kategorie,
          this.selectedCustomer,
          this.CustomerDetailsSelectedYear,
          this.CustomerDetailsSelectedYear,
          this.fibu_kto,
          this.code,
          this.art_gruppe,
          this.art_type,
          this.art_statcode*/
        )
        .then(response => {
          this.CustomerDocumentsItems = response.data
          console.log(response.data)
          this.CustomerDetails()
        })
        .catch(error => {
          console.error('Fehler bei der Abfrage der Kundendokumente:', error)
        })
    },

    CustomerDocumentsbyGroup() {
      this.CustomerDocumentsItems = []

      console.log(this.idfromxAxisCustomerDetails)
      customerDevelopmentService
        .getArticlesbyGroup(
          this.art_gruppe
          /*this.art_kategorie,
          this.art_kategorie,
          this.selectedCustomer,
          this.CustomerDetailsSelectedYear,
          this.CustomerDetailsSelectedYear,
          this.fibu_kto,
          this.code,
          this.art_type,
          this.art_statcode*/
        )
        .then(response => {
          this.CustomerDocumentsItems = response.data
          console.log(response.data)
          this.CustomerDetails()
        })
        .catch(error => {
          console.error('Fehler bei der Abfrage der Kundendokumente:', error)
        })
    },

    CustomerDocumentsbystatCode() {
      this.CustomerDocumentsItems = []

      console.log(this.idfromxAxisCustomerDetails)
      customerDevelopmentService
        .getArticlesbystatCode(
          this.art_statcode
          //this.art_gruppe,
          /*this.art_kategorie,
          this.art_kategorie,
          this.selectedCustomer,
          this.CustomerDetailsSelectedYear,
          this.CustomerDetailsSelectedYear,
          this.fibu_kto,
          this.code,
          this.art_type,
          */
        )
        .then(response => {
          this.CustomerDocumentsItems = response.data
          console.log(response.data)
          this.CustomerDetails()
        })
        .catch(error => {
          console.error('Fehler bei der Abfrage der Kundendokumente:', error)
        })
    },

    CustomerDocumentsbyErloesgroup() {
      this.CustomerDocumentsItems = []

      console.log(this.idfromxAxisCustomerDetails)
      customerDevelopmentService
        .getArticlesbyerloesgruppe(
          this.fibu_kto
          //this.art_statgroup
          //this.art_gruppe,
          /*this.art_kategorie,
          this.art_kategorie,
          this.selectedCustomer,
          this.CustomerDetailsSelectedYear,
          this.CustomerDetailsSelectedYear,
      
          this.code,
          this.art_type,
          */
        )
        .then(response => {
          this.CustomerDocumentsItems = response.data
          console.log(response.data)
          this.CustomerDetails()
        })
        .catch(error => {
          console.error('Fehler bei der Abfrage der Kundendokumente:', error)
        })
    },

    selectedTotalCustomerDevelopmentOfYear() {
      /*year,
      locations,
      addressType,
      customerGroup*/

      customerDevelopmentService
        .getTotalCustomerDevelopmentOfYear(
          this.getSelectedYear,
          this.selectBranche,
          this.selectedAddressType,
          this.selectedGroup
        )
        .then(response => {
          const yearData = response.data.find(
            year => year.year === this.yearStartSelection
          )
          //this.activeCustomer2 = [yearData.activeCustomer]
          //this.inactiveCustomer2 = [yearData.inactiveCustomer]
          this.totalCustomer2 = [yearData.totalCustomer]
          //this.newCustomer2 = [yearData.newCustomer]
          this.updateChart()
        })
        .catch(error => {
          console.error(error)
        })
    },
    selectedTotalCustomerDevelopmentOfFiveYear() {
      customerDevelopmentService
        .getTotalSupplierDevelopmentOfFiveYear(
          this.yearStartSelection,
          this.yearEndSelection,
          this.selectBranche,
          this.selectedAddressType,
          this.selectedGroup
        )
        .then(response => {
          response.data.forEach(year => {
            this.GraphYearYears.push(year.year)
            //this.activeCustomer2.push(year.activeCustomer)
            //this.inactiveCustomer2.push(year.inactiveCustomer)
            this.totalCustomer2.push(year.totalSupplier)
            //this.newCustomer2.push(year.newCustomer)
          })
          this.updateChart()
        })
        .catch(error => {
          console.error(error)
        })
    },
    CustomerDetails() {
      this.selectedCustomerName = this.selectedCustomer
      customerDevelopmentService
        .getCustomerDetailsType(
          2010,
          this.yearEndSelectionArticleType,

          this.selectedCustomer,
          'supplier',
          this.selectedTab
        )
        .then(response => {
          response.data.users.forEach(user => {
            const xAxisCategory = {
              name: user.name,
              subkdnr: user.subkdnr
            }
            this.xAxisCategoriesCustomerDetails.push(xAxisCategory.name)
            this.idfromxAxisCustomerDetails.push(xAxisCategory.subkdnr)
          })
          response.data.yearsData.forEach(yearData => {
            this.seriesDataCustomerDetails.push(yearData)
          })
          console.log('#########')
          console.log(this.idfromxAxisCustomerDetails)
          this.articleindex = [...this.idfromxAxisCustomerDetails]
          this.updateChart4()
        })
        .catch(error => {
          console.error(error)
        })
    },

    strongestCustomers() {
      if (this.YEAR != 0) {
        this.strongestCustomersyearmethod(this.YEAR)
      } else {
        this.checkstrongestCustomers()
      }
    },

    async getCustomerbyIndex(i) {
      if (this.switchstatus == true) {
        if (this.YEAR == 0) {
          try {
            const response = await customerDevelopmentService.getStrongestSuppliers2(
              this.strongestCustomersYear - 1,
              this.strongestCustomersYear + 1,
              this.selectBranche,
              this.selectedAddressType,
              this.selectedGroup,
              10
            )

            const number = response.data.users[i].subkdnr

            return number
          } catch (error) {
            console.error(error)
            throw error
          }
        } else {
          try {
            const response = await customerDevelopmentService.getStrongestSuppliers2(
              this.YEAR,
              this.YEAR,
              this.selectBranche,
              this.selectedAddressType,
              this.selectedGroup,
              10
            )

            const number = response.data.users[i].subkdnr

            return number
          } catch (error) {
            console.error(error)
            throw error
          }
        }
      } else {
        if (this.YEAR == 0) {
          try {
            const response = await customerDevelopmentService.getStrongestSuppliers(
              this.strongestCustomersYear - 1,
              this.strongestCustomersYear + 1,
              this.selectBranche,
              this.selectedAddressType,
              this.selectedGroup,
              10
            )

            const number = response.data.users[i].subkdnr

            return number
          } catch (error) {
            console.error(error)
            throw error
          }
        } else {
          try {
            const response = await customerDevelopmentService.getStrongestSuppliers(
              this.YEAR,
              this.YEAR,
              this.selectBranche,
              this.selectedAddressType,
              this.selectedGroup,
              10
            )

            const number = response.data.users[i].subkdnr

            return number
          } catch (error) {
            console.error(error)
            throw error
          }
        }
      }
    },

    checkstrongestCustomers() {
      if (this.switchstatus == false) {
        customerDevelopmentService
          .getStrongestSuppliers(
            this.strongestCustomersYear - 1,
            this.strongestCustomersYear + 1,

            this.selectBranche,
            this.selectedAddressType,
            this.selectedGroup,
            10
          )
          .then(response => {
            console.log(response.data)

            response.data.users.forEach(user => {
              this.xAxisCategories.push(user.name)
            })
            this.AllCustomers = this.xAxisCategories
            response.data.users.forEach(id => {
              this.AllCustomerId.push(id.subkdnr)
            })
            response.data.yearsData.forEach(yearData => {
              this.seriesData.push(yearData)
            })
            this.updateChart3()
          })
          .catch(error => {
            console.error(error)
          })
      } else {
        customerDevelopmentService
          .getStrongestSuppliers2(
            this.strongestCustomersYear - 1,
            this.strongestCustomersYear + 1,

            this.selectBranche,
            this.selectedAddressType,
            this.selectedGroup,
            10
          )
          .then(response => {
            console.log(response.data)

            response.data.users.forEach(user => {
              this.xAxisCategories.push(user.name)
            })
            this.AllCustomers = this.xAxisCategories
            response.data.users.forEach(id => {
              this.AllCustomerId.push(id.subkdnr)
            })
            response.data.yearsData.forEach(yearData => {
              this.seriesData.push(yearData)
            })
            this.updateChart3()
          })
          .catch(error => {
            console.error(error)
          })
      }
    },

    strongestCustomersyearmethod(year) {
      if (this.switchstatus == false) {
        customerDevelopmentService
          .getStrongestSuppliers(
            year,
            year,

            this.selectBranche,
            this.selectedAddressType,
            this.selectedGroup,
            10
          )
          .then(response => {
            console.log(response.data)

            response.data.users.forEach(user => {
              this.xAxisCategories.push(user.name)
            })
            this.AllCustomers = this.xAxisCategories
            response.data.users.forEach(id => {
              this.AllCustomerId.push(id.subkdnr)
            })
            response.data.yearsData.forEach(yearData => {
              this.seriesData.push(yearData)
            })
            this.updateChart3year(year)
          })
          .catch(error => {
            console.error(error)
          })
      } else {
        customerDevelopmentService
          .getStrongestSuppliers2(
            year,
            year,
            this.selectBranche,
            this.selectedAddressType,
            this.selectedGroup,
            10
          )
          .then(response => {
            console.log(response.data)

            response.data.users.forEach(user => {
              this.xAxisCategories.push(user.name)
            })
            this.AllCustomers = this.xAxisCategories
            response.data.users.forEach(id => {
              this.AllCustomerId.push(id.subkdnr)
            })
            response.data.yearsData.forEach(yearData => {
              this.seriesData.push(yearData)
            })
            this.updateChart3year(year)
          })
          .catch(error => {
            console.error(error)
          })
      }
    },

    ListOfAllPersons(type) {
      let list = []
      customerDevelopmentService.getAllPersonList(type).then(response => {
        response.data.forEach(persons => {
          list.push(persons)
        })
      })
      return list
    },

    customerDbRevenue() {
      customerDevelopmentService
        .getDBPosNetfromSupplier(
          this.yearStartSelection,
          this.yearEndSelection,
          this.selectBranche,
          this.selectedAddressType,
          this.selectedGroup
        )
        .then(response => {
          response.data.forEach(year => {
            this.revenue.push(parseFloat(year.pos_netto).toFixed(2))
            this.db.push(parseFloat(year.db).toFixed(2))
          })
          this.updateChart2()
        })
        .catch(error => {
          console.error(error)
        })
    },
    selectedCustomerGroups() {
      this.$store.dispatch('setCustomerGroup', this.selectedGroup)
      this.selectedTotalCustomerDevelopmentOfFiveYear()
    },
    selectedAddressTypes() {
      this.$store.dispatch('setAddressType', this.selectedAddressType)
      this.selectedTotalCustomerDevelopmentOfFiveYear()
    },
    selectedBranches() {
      this.$store.dispatch('setLocations', this.selectBranche)
      this.selectedTotalCustomerDevelopmentOfFiveYear()
    },
    updateChart4() {
      this.customerDetailsOptions.xaxis.categories = this.xAxisCategoriesCustomerDetails
      let tmp = []
      this.seriesDataCustomerDetails.forEach(y => {
        if (
          y.year >= this.CustomerDetailsYear - 1 &&
          y.year <= this.CustomerDetailsYear + 1
        )
          tmp.push({ name: y.year, data: y.data })
      })
      this.tmp = tmp
      this.$refs.totalChart4.updateSeries(tmp),
        this.$refs.totalChart4.updateOptions({
          xaxis: {
            categories: this.xAxisCategoriesCustomerDetails
          }
        })
      this.seriesDataCustomerDetails = []
      this.xAxisCategoriesCustomerDetails = []

      this.fibu_kto = ''
      this.code = ''
      this.art_gruppe = ''
      this.art_kategorie = ''
      this.art_type = ''
      this.art_statcode = ''

      switch (this.selectedTab) {
        case 'artikelgruppe':
          this.art_gruppe = this.idfromxAxisCustomerDetails[
            this.dataPointIndexGraph
          ]
          break
        case 'artikeltypen':
          this.art_type = this.idfromxAxisCustomerDetails[
            this.dataPointIndexGraph
          ]
          break
        case 'artikelkategorie':
          this.art_kategorie = this.idfromxAxisCustomerDetails[
            this.dataPointIndexGraph
          ]
          break
        case 'statistikgruppen':
          this.code = this.idfromxAxisCustomerDetails[this.dataPointIndexGraph]
          break
        case 'erloesgruppen':
          this.fibu_kto = this.idfromxAxisCustomerDetails[
            this.dataPointIndexGraph
          ]
          break
        case 'statistikcode':
          this.art_statcode = this.idfromxAxisCustomerDetails[
            this.dataPointIndexGraph
          ]
          break
      }

      this.idfromxAxisCustomerDetails = this.subkdnrCustomerDetails
      this.idfromxAxisCustomerDetails = []
    },

    updateChart3() {
      this.strongestCustomersOptions.xaxis.categories = this.xAxisCategories
      let tmp = []

      this.seriesData.forEach(y => {
        if (
          y.year >= this.strongestCustomersYear - 1 &&
          y.year <= this.strongestCustomersYear + 1
        )
          tmp.push({ name: y.year, data: y.data })
      })
      this.$refs.totalChart3.updateSeries(tmp),
        this.$refs.totalChart3.updateOptions({
          xaxis: {
            categories: this.xAxisCategories
          },
          yaxis: {
            labels: {
              formatter: function(value) {
                const formatter = new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
                return formatter.format(value).replace(/\s/g, '')
              }
            }
          }
        })
      this.seriesData = []
      this.xAxisCategories = []
    },

    updateChart3year(year) {
      this.strongestCustomersOptions.xaxis.categories = this.xAxisCategories
      let tmp = []

      this.seriesData.forEach(y => {
        if (y.year >= year - 1 && y.year <= year + 1)
          tmp.push({ name: y.year, data: y.data })
      })
      this.$refs.totalChart3.updateSeries(tmp),
        this.$refs.totalChart3.updateOptions({
          xaxis: {
            categories: this.xAxisCategories
          },
          yaxis: {
            labels: {
              formatter: function(value) {
                const formatter = new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
                return formatter.format(value).replace(/\s/g, '')
              }
            }
          }
        })
      this.seriesData = []
      this.xAxisCategories = []
    },

    updateChart2() {
      const startYear = this.yearStartSelection
      const endYear = this.yearEndSelection
      const xAxisCategories = []

      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString())
      }

      this.customerDbRevenueOptions.xaxis.categories = xAxisCategories

      this.$refs.totalChart2.updateSeries([
        {
          name: 'Deckungsbetrag',
          data: this.db
        },
        {
          name: 'Einnahmen',
          data: this.revenue
        }
      ]),
        this.$refs.totalChart2.updateOptions({
          xaxis: {
            categories: xAxisCategories
          }
        })
      this.db = []
      this.revenue = []
    },
    updateChart() {
      const startYear = this.yearStartSelection
      const endYear = this.yearEndSelection
      const xAxisCategories = []

      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString())
      }
      this.totalChartOptions.xaxis.categories = xAxisCategories
      this.$refs.totalChart.updateSeries([
        {
          name: 'Gesamt',
          data: this.totalCustomer2
        }
      ]),
        this.$refs.totalChart.updateOptions({
          xaxis: {
            categories: xAxisCategories
          }
        })
      this.totalCustomer2 = []
    },
    yearStartSelectionArticleTypeChange(selected) {
      this.$store.dispatch('setYearStart', selected)
      this.$store.dispatch('setMonthly', false)
      this.CustomerDetails()
    },
    yearEndSelectionChangeArticleType(selected) {
      this.$store.dispatch('setYearEnd', selected)
      this.$store.dispatch('setMonthly', false)
      this.CustomerDetails()
    },
    yearStartSelectionChange(selected) {
      this.$store.dispatch('setYearStart', selected)
      this.$store.dispatch('setMonthly', false)
      this.selectedTotalCustomerDevelopmentOfFiveYear()
    },
    yearEndSelectionChange(selected) {
      this.$store.dispatch('setYearEnd', selected)
      this.$store.dispatch('setMonthly', false)
      this.selectedTotalCustomerDevelopmentOfFiveYear()
    },
    yearBack1() {
      let yearStart = this.yearStartSelection
      if (yearStart != this.getMinYear) {
        if (this.getSelectedYear > this.yearEndSelection - 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear - 1)
        }
        this.yearStartSelection = yearStart - 1
        this.yearEndSelection -= 1
        this.selectedTotalCustomerDevelopmentOfFiveYear()
      }
    },
    yearForward1() {
      let yearEnd = this.yearEndSelection
      if (yearEnd != this.getCurrentYear) {
        if (this.getSelectedYear < this.yearStartSelection + 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear + 1)
        }

        this.yearStartSelection += 1
        this.yearEndSelection = yearEnd + 1
        this.selectedTotalCustomerDevelopmentOfFiveYear()
      }
    },
    yearBack2() {
      let yearStart = this.yearStartSelection
      if (yearStart != this.getMinYear) {
        if (this.getSelectedYear > this.yearEndSelection - 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear - 1)
        }
        this.yearStartSelection = yearStart - 1
        this.yearEndSelection -= 1
        this.customerDbRevenue()
      }
    },
    yearForward2() {
      let yearEnd = this.yearEndSelection
      if (yearEnd != this.getCurrentYear) {
        if (this.getSelectedYear < this.yearStartSelection + 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear + 1)
        }

        this.yearStartSelection += 1
        this.yearEndSelection = yearEnd + 1
        this.customerDbRevenue()
      }
    }
  },
  watch: {
    fiveYearDataArray() {
      this.updateChart()
      this.updateChart2()
    },
    getYearStartAndgetYearEnd() {
      this.yearStartSelection = this.getYearStart
      this.yearEndSelection = this.getYearEnd
    }
  }
}
</script>
<style scoped>
.v-select__selections {
  font-weight: bold;
  padding-right: 50px;
}
.v-select__selections .v-select.fit {
  width: 100px;
}
.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}
#addressType {
  width: 100%;
}
#yearContainer {
  display: flex;
}
#StartYear {
  flex: 1;
}
#EndYear {
  flex: 1;
}
#CustomerGroup {
  width: 100%;
}
#Branches {
  width: 100%;
}
#graph1 {
  flex: 1;
}
#graph2 {
  flex: 1;
}
#graph12 {
  display: flex;
}
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}
.tab button:hover {
  background-color: #ddd;
}
.tab button.active {
  background-color: #ccc;
}
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.filter-container {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  transition: height 500ms;
}
.filter-header {
  cursor: pointer;
  padding: 10px;
  background-color: #f0f0f0;
  transition: height 500ms;
}
.filter-content {
  overflow-y: hidden;
  transition: max-height 0.3s ease;
  padding: 10px;
}
.filter-container.collapsed .filter-content {
  max-height: 0;
  border: none;
}

.switch {
  position: sticky;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.switch::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
}

.switch::before {
  content: 'logarithmisch aus';
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: pointer;

  opacity: 0;
  transition: opacity 0.3s ease;
}

.switch::after {
  content: 'logarithmisch ein / aus';
  display: inline-block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: #333;
  font-size: 14px;
  background-color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 3;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.switch:hover::after {
  opacity: 1;
}

.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
