<template>
  <v-container
    ><SiteHeaderComponent :header="address.name" />
    <div class="text-center grey--text text--darken-2 mb-12">
      <h3>Artikeltypen</h3>
    </div>
    <div class="d-flex justify-end">
      <v-btn
        class="rounded-pill"
        color="primary"
        :to="{
          name: 'statisticsArticleSearch',
          params: { lfdnr: lfdnr },
          query: this.$route.query
        }"
      >
        <v-icon class="mr-2"> mdi-magnify </v-icon>
        Artikelsuche
      </v-btn>
    </div>
    <div>
      <TimeFilter />
    </div>
    <v-divider></v-divider>

    <div id="chart" class="mt-4">
      <div>
        <h5 class="text-h5 text-center">Gesamt</h5>
      </div>
      <apexchart
        ref="summaryChart"
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>

    <v-divider></v-divider>
    <div class="my-4">
      <h5 class="text-h5 text-center">Einzelstatistik</h5>
    </div>

    <v-card class="mx-auto mb-12">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          outlined
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="articleTypes"
        :items-per-page="30"
        :search="search"
        item-key="code"
        class="elevation-1 datatable-row-pointer"
        :loading="loading"
        sort-by="description"
        :sort-desc="sortDesc"
        mobile-breakpoint="800"
        @click:row="openArticleType"
      >
        <template v-slot:no-data>
          Keine Artikeltypen in diesem Zeitraum vorhanden.
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import addressService from '@/services/crm/addressService.js'
import distributorService from '@/services/statistics/distributorService.js'

import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue'
import TimeFilter from '@/components/statistics/TimeFilter.vue'

export default {
  components: {
    SiteHeaderComponent,
    TimeFilter
  },
  data: () => ({
    address: {
      name: '',
      street: '',
      postcode: '',
      city: '',
      country: '',
      tel: '',
      email: '',
      addressTypes: []
    },
    articleTypes: [],
    loading: true,
    search: '',
    sortDesc: false,
    editorContent: '',
    headers: [
      {
        text: 'Bezeichnung',
        align: 'start',
        value: 'description'
      },
      {
        text: 'Anfangsbestand (€)',
        value: 'openingValue',
        align: 'end'
      },
      {
        text: 'Einkäufe (€)',
        value: 'purchasesValue',
        align: 'end'
      },
      {
        text: 'Verkäufe (€)',
        value: 'salesValue',
        align: 'end'
      },
      {
        text: 'Inventur (€)',
        value: 'inventoryValue',
        align: 'end'
      },
      {
        text: 'Materialverbrauch (€)',
        value: 'materialsInputValue',
        align: 'end'
      },
      {
        text: 'Endbestand (€)',
        value: 'finalValue',
        align: 'end'
      }
    ],
    // Chart
    series: [
      {
        name: 'Euro (€)',
        data: []
      }
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: 'bar'
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top' // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758']
        }
      },

      xaxis: {
        categories: [],
        position: 'top',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      title: {
        text: 'Gesamtstatistik',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444'
        }
      },
      colors: ['#FF6633']
    }
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    }
  },
  methods: {
    init() {
      addressService.getAddress(this.lfdnr).then(response => {
        this.address = response.data
      })
      this.loadArticleTypeStatistics()
    },
    loadArticleTypeStatistics() {
      console.log('loadArticeleadsfasdfasdfasdfasdfasdf')
      this.loading = true
      distributorService
        .getArticleTypesStatistics(
          this.lfdnr,
          this.filterFromDate,
          this.filterToDate
        )
        .then(response => {
          this.articleTypes = response.data
          this.loading = false
        })

      distributorService
        .getArticleTypesSummaryStatistics(
          this.lfdnr,
          this.filterFromDate,
          this.filterToDate
        )
        .then(response => {
          var obj = response.data
          var chartData = [
            obj.openingValue,
            obj.purchasesValue,
            obj.salesValue,
            obj.inventoryValue,
            obj.materialsInputValue,
            obj.finalValue
          ]

          this.updateChart(chartData)
        })
    },
    openArticleType(item) {
      console.log('test')
      // statisticsDistributorArticleCategories
      this.$router.push({
        name: 'statisticsDistributorArticleCategories',
        query: {
          from: this.filterFromDate,
          to: this.filterToDate
        },
        params: { lfdnr: this.lfdnr, articleType: item.code }
      })
    },
    updateChart(chartData) {
      var chartHeaders = []

      this.headers.forEach(item => {
        if (item.text.includes('€')) chartHeaders.push(item.text)
      })

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: chartHeaders
          },
          title: {
            text: this.address.name + ' - Gesamtstatistik'
          }
        }
      }

      this.$refs.summaryChart.updateSeries(
        [
          {
            data: chartData
          }
        ],
        false,
        true
      )
    }
  },
  computed: {
    ...mapState({
      filterFromDate: state => state.statisticsTimeFilter.fromDate,
      filterToDate: state => state.statisticsTimeFilter.toDate
    })
  },
  mounted() {
    this.init()
  },
  watch: {
    filterFromDate: function() {
      this.loadArticleTypeStatistics()
    },
    filterToDate: function() {
      this.loadArticleTypeStatistics()
    }
  }
}
</script>

<style scoped>
.datatable-row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
