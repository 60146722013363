<template>
  <div>
    <RevenueChart
      ref="yearChart"
      chartType="year"
      :chartSeries="chartSeries"
      :chartYaxisData="yearLabels"
      :totalSeries="totalSeries"
      :legendItems="legendItems"
      :titleText="
        `Jahresvergleich - ${getTitle} ${
          getUntilCurrentMonth ? 'bis zum aktuellen Monat' : ''
        }`
      "
      xaxisText="Jahr"
      :yaxisText="getTitle"
      :stackedChart="true"
      :showBothRevenueTypes="showBothRevenueTypes"
      @updateCharts="$emit('updateCharts')"
    ></RevenueChart>
  </div>
</template>

<script>
// Services
import RevenueService from '@/services/statistics/revenueService.js'

// Components
import RevenueChart from '@/components/statistics/Revenue/RevenueChart.vue'

// Vue Store
import { mapGetters } from 'vuex'

export default {
  components: {
    RevenueChart
  },
  data() {
    return {
      chartSeries: [],
      yearLabels: [],
      legendItems: [],
      totalSeries: []
    }
  },
  props: {
    showBothRevenueTypes: Boolean
  },
  computed: {
    ...mapGetters([
      'getYearStart',
      'getYearEnd',
      'getShops',
      'getUntilCurrentMonth',
      'getIncludeCurrentMonth',
      'getShowBothRevenueTypes',
      'getSelectedRevenueTypeShort',
      'getSelectedFilters'
    ]),

    getTitle() {
      return RevenueService.getRevenueName(this.getRevenueTypeShort)
    },

    getRevenueTypeShort() {
      if (this.getShowBothRevenueTypes && this.showBothRevenueTypes) {
        return 'db'
      } else if (this.getShowBothRevenueTypes && !this.showBothRevenueTypes) {
        return 'net'
      }
      return this.getSelectedRevenueTypeShort
    }
  },
  watch: {
    getIncludeCurrentMonth() {
      this.updateRevenueOfYearSpan()
    }
  },
  methods: {
    updateRevenueOfYearSpan() {
      let chartSeries = []
      let yearLabels = []
      let legendItems = []
      let totalSeries = []

      if (
        this.getYearEnd < this.getYearStart ||
        this.getYearStart > this.getYearEnd
      ) {
        this.chartSeries = chartSeries
        this.yearLabels = yearLabels
        this.legendItems = legendItems
        this.totalSeries = totalSeries
        return
      }

      RevenueService.getRevenueForYearSpan(
        this.getYearStart,
        this.getYearEnd,
        this.getUntilCurrentMonth,
        this.getIncludeCurrentMonth,
        this.getSelectedFilters
      ).then(revenues => {
        if (revenues.status === 200) {
          if (!RevenueService.isRevenueResponseValid(revenues)) {
            this.chartSeries = chartSeries
            this.yearLabels = yearLabels
            this.legendItems = legendItems
            this.totalSeries = totalSeries
            return
          }

          yearLabels = Object.keys(revenues.data)

          yearLabels.forEach(year => {
            totalSeries.push({
              title: year,
              net: revenues.data[year].net,
              db: revenues.data[year].db
            })
          })

          const firstYear = yearLabels[0]
          const shopsRevenue = revenues.data[firstYear].shopsRevenue
          const shopIds = []

          Object.keys(shopsRevenue).forEach(shopId => {
            const shopRevenue = shopsRevenue[shopId]
            let includeShop = false

            Object.keys(revenues.data).forEach(year => {
              const shopRevenueForYear =
                revenues.data[year].shopsRevenue[shopId]
              if (
                shopRevenue &&
                (shopRevenueForYear.net !== 0 || shopRevenueForYear.db !== 0)
              ) {
                includeShop = true
                return
              }
            })

            if (includeShop) {
              shopIds.push(shopId)
              const shop = this.getShops.find(shop => shop.id === shopId)
              if (shop) {
                legendItems.push(shop.description)
              }
            }
          })

          chartSeries = shopIds.map(shopId => ({
            data: [],
            name: `${shopId} - ${this.getTitel}`,
            type: 'bar',
            group: this.getRevenueTypeShort,
            shopId
          }))

          yearLabels.forEach(year => {
            chartSeries.forEach(seriesObject => {
              const revenue =
                revenues.data[year].shopsRevenue[seriesObject.shopId][
                  seriesObject.group
                ]
              seriesObject.data.push(revenue)
            })
          })

          this.chartSeries = chartSeries
          this.yearLabels = yearLabels
          this.legendItems = legendItems
          this.totalSeries = totalSeries
        } else {
          this.$store.dispatch(
            'setFailMessage',
            'Es trat ein Fehler beim Laden der Statistik auf: Jahresvergleich'
          )
          this.$store.dispatch('setErrorDialog', true)
          console.error(revenues.stack)
        }
      })
    }
  },

  mounted() {
    if (this.showBothRevenueTypes) {
      this.updateRevenueOfYearSpan()
    }
  }
}
</script>

<style></style>
