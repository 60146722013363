<template>
  <v-container>
    <div class="mx-auto my-12 v-card v-sheet theme--light">
      <v-tabs grow v-model="currentTab" show-arrows>
        <!-- <v-tab href="#offers">Angebote</v-tab> -->
        <v-tab href="#documents">Dokumentarten</v-tab>
        <v-tab href="#customers">Kunden</v-tab>
        <v-tab href="#delivery">Lieferanten</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item value="offers">
          <Offers />
        </v-tab-item>
        <v-tab-item value="documents">
          <Documents />
        </v-tab-item>
        <v-tab-item value="customers">
          <Customers />
        </v-tab-item>
        <v-tab-item value="delivery">
          <delivery />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>
<script>
/*
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    setup() {
        
    },
})
*/

import Offers from '@/components/statistics/customerDevelopment/yearComparisonOffer.vue'
import Customers from '@/components/statistics/customerDevelopment/totalDevelopmentNew.vue'
import Delivery from '@/components/statistics/customerDevelopment/delivery.vue'
import Documents from '@/components/statistics/customerDevelopment/documentTypes.vue'
export default {
  components: {
    Offers,
    Documents,
    Customers,
    Delivery
  },

  data: () => ({
    currentTab: ''
  })
}
</script>
