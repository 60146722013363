<template>
  <v-container>
    <div>
      <h2>
        {{ headLineText }}
        <span class="subtitle-1"
          >{{ this.yearStartSelection }}-{{ this.yearEndSelection }}</span
        >
      </h2>
    </div>
    <v-expansion-panels class="py-4 mx-auto" v-model="filterPanel">
      <v-expansion-panel>
        <v-expansion-panel-header class="py-0" expand-icon="mdi-menu-down">
          <v-container>
            <v-layout row wrap class="d-flex align-center">
              <span class="mr-2 pt-1"
                ><v-icon>mdi-filter-outline</v-icon>Filter</span
              >
            </v-layout>
          </v-container>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="filter-content" v--if="!isCollapsed">
            <div id="FilterContainer" class="filter-container">
              <div class="filter-item">
                <div class="form-control-container">
                  <v-select
                    ref="yearChart"
                    hint="Anfangsjahr"
                    persistent-hint
                    v-model="yearStartSelection"
                    @change="startdocumentTypesAndofferGraphs"
                    :items="generateYearsOptions()"
                  >
                  </v-select>
                </div>
              </div>
              <div class="filter-item">
                <div class="form-control-container">
                  <v-select
                    ref="yearChart"
                    hint="Endjahr"
                    persistent-hint
                    :items="generateYearsOptions()"
                    v-model="yearEndSelection"
                    @change="startdocumentTypesAndofferGraphs"
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <div id="DocumentGroups">
              <v-select
                class="mb-5 mt-3"
                :items="documentGroup"
                item-text="form_bezeichnung"
                item-value="form_gruppe"
                label="Dokumentgruppen"
                chips
                clearable
                deletable-chips
                v-model="selectedDocumentGroup"
                single
                @change="startdocumentTypesAndofferGraphs"
                ><v-chip close></v-chip>
              </v-select>
            </div>
            <br />
            <div class="outer-container rounded">
              <h3>Artikelfilter</h3>
              <br />
              <v-row>
                <!--Combobox zum Auswählen der Artikeltypen-->
                <v-autocomplete
                  class="mx-sm-4 mx-lg-3"
                  :items="articleTypes"
                  item-text="description"
                  v-model="art_type"
                  return-object
                  label="Artikeltyp"
                  clearable
                ></v-autocomplete>
              </v-row>
              <v-row>
                <v-col>
                  <!--Combobox zum Auswählen der Artikelkategorie-->
                  <v-autocomplete
                    :items="articleCategories"
                    item-text="description"
                    v-model="art_kategorie"
                    return-object
                    label="Artikelkategorie"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <!--Combobox zum Auswählen der Artikelgruppen-->
                  <v-autocomplete
                    :items="articleGroups"
                    item-text="description"
                    v-model="art_gruppe"
                    return-object
                    label="Artikelgruppe"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!--Combobox zum Auswählen der Statistikgruppe-->
                  <v-autocomplete
                    :items="statisticGroups"
                    item-text="description"
                    v-model="statistic_gruppe"
                    return-object
                    label="Statistikgruppe"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <!--Combobox zum Auswählen des Statistikcodes-->
                  <v-autocomplete
                    :items="statisticCodes"
                    item-text="code"
                    v-model="statistic_code"
                    return-object
                    label="Statistikcode"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!--Combobox zum Auswählen der Provisionsgruppe-->
                  <v-autocomplete
                    :items="commissionGroups"
                    item-text="bezeichnung"
                    v-model="prov_gruppe"
                    return-object
                    label="Provisionsgruppe"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <!--Combobox zum Auswählen der Erlösgruppe-->
                  <v-autocomplete
                    :items="revenueGroups"
                    item-text="name"
                    v-model="erloes_gruppe"
                    return-object
                    label="Erlösgruppe"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!--Combobox zum Auswählen des Objekttyen-->
                  <v-autocomplete
                    :items="objectTypes"
                    item-text="description"
                    v-model="objekt_typ"
                    return-object
                    label="Objekttyp"
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <!--Combobox zum Auswählen der Objekttypengruppe-->
                  <v-autocomplete
                    :items="objectTypeGroups"
                    item-text="description"
                    v-model="objekt_typ_gruppe"
                    return-object
                    label="Objekttypengruppe"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <!--   
              ===================  Filter laden Daten aus Backend, können aber noch nicht auf Graphen angewendet werden   ==============

          <FilterSelect
                    label="Artikeltyp"
                    type="articleTypes"
                    @change="startdocumentTypesAndofferGraphs"
                    :disabled="false"
                  />
              
            
        
          <v-row class="d-flex no-gutters mt-6 mb-2">
            <v-col class="col-break">

                  <FilterSelect
                    label="Artikelkategorie"
                    type="articleCategories"
                    v-model="art_kategorie"
                    @change="updateChart1"
                    :disabled="false"
                   
                  />
              
                  <FilterSelect
                    label="Statistikgruppe"
                    type="statisticGroups"
                    @change="startdocumentTypesAndofferGraphs"
                    v-model="statistic_gruppe"
                    :disabled="false"
                  />

                  <FilterSelect
                    label="Provisionsgruppe"
                    type="prov_gruppe"
                    @change="updateCharts"
                    :disabled="false"
                  />

                  <FilterSelect
                    label="Objekttyp"
                    type="objekt_typ"
                    @change="updateCharts"
                    :disabled="false"
                  
                  />

                </v-col>
               
                <v-divider :vertical=true class="mx-4"></v-divider>
                <v-col class="col-break">

                  <FilterSelect
                    label="Artikelgruppe"
                    type="articleGroups"
                    @change="updateCharts"
                    :disabled="false"
                  />

                  <FilterSelect
                    label="Statistikcode"
                    type="statisticCode"
                    @change="updateCharts"
                    :disabled="false"
                  />
              
                  <FilterSelect
                    label="Erlösgruppen"
                    type="revenueGroups"
                    @change="updateCharts"
                    :disabled="false"
                  />

                  <FilterSelect
                    label="Objekttypgruppe"
                    type="objekt_typ_gruppe"
                    @change="updateCharts"
                    :disabled="true"
                  />

                </v-col>
              </v-row>

            -->

              <v-btn @click="addArticle">Anwenden</v-btn><br /><br />

              <!--  <v-chip clearable v-for="(item, index) in filterArticleName" v-bind:item="item" v-bind:index="index"
            v-bind:key="item.id" @click:close="articleClose(index)" closable close>
            <v-icon>mdi-filter</v-icon>
            <div v-if="item.art_kategorie != ''">
              Artikelkategorie: {{ item.art_kategorie }}
              <span v-if="item.art_type != '' || item.art_gruppe != ''">&nbsp; & &nbsp;</span>
            </div>

            <div v-if="item.art_type != ''">
              Artikeltyp: {{ item.art_type }}
              <span v-if="item.art_gruppe != ''">&nbsp; & &nbsp;</span>
            </div>

            <div v-if="item.art_gruppe != ''">
              Artikelgruppe: {{ item.art_gruppe }}
            </div>
            
          </v-chip>-->
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <br />

    <h3>Platzhalter für aktive Filterfenster</h3>

    <div id="graph">
      <div class="graph-container">
        <h3>
          {{
            headlineTextJahresver +
              this.getDocumentGroupName(this.selectedDocumentGroup)
          }}
        </h3>
        <v-btn icon color="black" @click="yearBack">
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
        <v-btn icon color="black" @click="yearForward">
          <v-icon>mdi-arrow-right-thick</v-icon>
        </v-btn>
      </div>
      <apexchart
        class="apexchart"
        ref="totalChart"
        type="bar"
        height="350"
        :options="documentTypesOptions"
        :series="documentTypesSeries"
      ></apexchart>
    </div>

    <div id="chartContainer">
      <div id="graph1">
        <h3>
          {{
            headlineTextDokumentanz +
              this.getDocumentGroupName(this.selectedDocumentGroup)
          }}
        </h3>

        <apexchart
          ref="totalChart1"
          type="bar"
          height="350"
          :options="offerGraphOptions"
          :series="offerGraphSeries"
        >
        </apexchart>
      </div>
      <div id="graph2">
        <h3>
          {{
            headlineTextUmsatzDb +
              this.getDocumentGroupName(this.selectedDocumentGroup)
          }}
        </h3>
        <apexchart
          ref="totalChart2"
          type="bar"
          height="350"
          :options="offerGraph2Options"
          :series="offerGraph2Series"
        >
        </apexchart>
      </div>
    </div>
  </v-container>
</template>
<script>
//import FilterSelect from '@/components/statistics/Revenue/FilterSelect.vue'
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    //FilterSelect,
  },

  created() {
    this.documentTypes()
    this.documentGroups()
    this.offerGraph()
    this.generateYearsOptions()
    this.loadData()
  },
  beforeMount() {
    this.loadData()
    this.offerGraph()
    this.documentTypes()
    this.documentGroups()
  },
  mounted() {
    this.documentTypes()
    this.loadData()
    this.offerGraph()
  },
  computed: {
    ...mapState({
      //filtersActive: state => state.crmFilter.filterActivated,
      articleTypes: state => state.articleFilter.articleTypes,
      articleCategories: state => state.articleFilter.articleCategories,
      articleGroups: state => state.articleFilter.articleGroups,
      statisticGroups: state => state.articleFilter.statisticGroups,
      statisticCodes: state => state.articleFilter.statisticCodes,
      commissionGroups: state => state.articleFilter.commissionGroups,
      revenueGroups: state => state.articleFilter.revenueGroups,
      objectTypes: state => state.articleFilter.objectTypes,
      objectTypeGroups: state => state.articleFilter.objectTypeGroups
      //filter: state => state.articleFilter.filter,
      //visibleTabs: state => state.articlePermissions.visibleTabs
      //foundArticles: state => state.articleFilter.foundArticles
    }),
    ...mapGetters(['getFlter'])
  },

  data: function() {
    return {
      headLineText: 'Gesamtentwicklung der Dokumentarten',
      headlineTextUmsatzDb: 'Umsatz / DB: ',
      headlineTextJahresver: 'Jahresvergleich Dokumentgruppen: ',
      headlineTextDokumentanz: 'Dokumentanzahl: ',
      yearStartSelection: new Date().getFullYear() - 4,
      yearEndSelection: new Date().getFullYear(),
      CustomerDetailsYear: new Date().getFullYear() - 1,
      DB: [],
      UM: [],

      tDB: [],
      tUM: [],

      Amount: [],
      offerAll: [],
      offerOpen: [],
      offerDB: [],
      offerUM: [],

      art_kategorie: '',
      art_type: '',
      art_gruppe: '',
      statistic_gruppe: '',
      statistic_code: '',
      erloes_gruppe: '',
      prov_gruppe: '',
      objekt_typ: '',
      objekt_typ_gruppe: '',

      documentGroup: [],
      documentGroupID: [],
      selectedDocumentGroup: '40',

      filterArticle: [],
      filterArticleName: [],

      offerGraph2Series: {},
      offerGraphSeries: {},

      isCollapsed: false,

      documentTypesOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex)
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              return formatter.format(value).replace(/\s/g, '')
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },

      offerGraphOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex)
            }
          }
        },
        dataLabels: {
          enabled: true
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },

      offerGraph2Options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              this.$emit('showNewCustomers', config.dataPointIndex)
            }
          }
        },
        dataLabels: {
          enabled: false
        },

        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
              return formatter.format(value).replace(/\s/g, '')
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      }
    }
  },
  methods: {
    getDocumentGroupName(selectedGroupValue) {
      const selectedGroup = this.documentGroup.find(
        group => group.form_gruppe === selectedGroupValue
      )
      return selectedGroup ? selectedGroup.form_bezeichnung : ''
    },

    toggleFilters() {
      this.isCollapsed = !this.isCollapsed
    },
    articleClose(index) {
      this.filterArticle.splice(index, 1)
      this.filterArticleName.splice(index, 1)
      this.documentTypes()
      this.offerGraph()
    },

    startdocumentTypesAndofferGraphs() {
      this.documentTypes()
      this.offerGraph()
    },

    generateYearsOptions() {
      let currentYear = new Date().getFullYear()
      let options = []

      for (let i = currentYear; i >= 2010; i--) {
        options.push({ text: i.toString(), value: i })
      }
      return options
    },

    documentTypes() {
      console.log(this.selectedDocumentGroup)
      console.log(this.statistic_gruppe)
      customerDevelopmentService
        .getDocumentsTypes(
          this.yearStartSelection,
          this.yearEndSelection,
          this.min_netto,
          this.max_netto,
          this.minBetragSelection,
          this.maxBetragSelection,
          this.selectedDocumentGroup,
          this.filterArticle,
          this.statistic_gruppe,
          this.statistic_code,
          this.prov_gruppe,
          this.erloes_gruppe,
          this.objekt_typ,
          this.objekt_typ_gruppe
        )
        .then(response => {
          console.log(response.data)
          response.data.forEach(data => {
            this.DB.push(data.db)
            this.UM.push(data.um)
            this.Amount.push(data.amount)
          })

          this.updateChart()
        })
        .catch(error => {
          console.error(error)
        })
    },
    updateChart() {
      const startYear = this.yearStartSelection
      const endYear = this.yearEndSelection
      const xAxisCategories = []

      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString())
      }

      this.documentTypesOptions.xaxis.categories = xAxisCategories

      this.$refs.totalChart.updateSeries([
        {
          name: 'Deckungsbetrag',
          data: this.DB
        },
        {
          name: 'Umsatzsumme',
          data: this.UM
        }
        /*{
          name: 'Anzahl',
          data: this.Amount
        }*/
      ]),
        this.$refs.totalChart.updateOptions({
          xaxis: {
            categories: xAxisCategories
          }
        })
      this.DB = []
      this.UM = []
      this.Amount = []
    },
    yearBack() {
      let yearStart = this.yearStartSelection
      if (yearStart != this.getMinYear) {
        if (this.getSelectedYear > this.yearEndSelection - 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear - 1)
        }
        this.yearStartSelection = yearStart - 1
        this.yearEndSelection -= 1
        this.documentTypes()
        this.offerGraph()
      }
    },
    yearForward() {
      let yearEnd = this.yearEndSelection
      if (yearEnd != this.getCurrentYear) {
        if (this.getSelectedYear < this.yearStartSelection + 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear + 1)
        }
        this.yearStartSelection += 1
        this.yearEndSelection = yearEnd + 1
        this.documentTypes()
        this.offerGraph()
      }
    },
    loadData() {
      this.$store.dispatch('loadArticleTypes')
      this.$store.dispatch('loadArticleCategories')
      this.$store.dispatch('loadArticleGroups')
      this.$store.dispatch('loadStatisticGroups')
      this.$store.dispatch('loadStatisticCodes')
      this.$store.dispatch('loadCommissionGroups')
      this.$store.dispatch('loadRevenueGroups')
      this.$store.dispatch('loadObjectTypes')
      this.$store.dispatch('loadObjectTypeGroups')
      //this.$store.dispatch('loadVisibleTabs')

      const storedFilter = this.$store.getters.getFlter
      this.articleType = storedFilter.articleType
      this.articleCategory = storedFilter.articleCategory
      this.articleGroup = storedFilter.articleGroup
      this.statisticGroup = storedFilter.statisticGroup
      this.statisticCode = storedFilter.statisticCode
      this.commissionGroup = storedFilter.commissionGroup
      this.revenueGroup = storedFilter.revenueGroup
      this.objectType = storedFilter.objectType
      this.objectTypeGroup = storedFilter.objectTypeGroup
    },
    addArticle() {
      if (
        (this.art_kategorie == '' || this.art_kategorie == null) &&
        (this.art_type == '' || this.art_type == null) &&
        (this.art_gruppe == '' || this.art_gruppe == null) &&
        (this.statistic_gruppe == '' || this.statistic_gruppe == null) &&
        (this.statistic_code == '' || this.statistic_code == null) &&
        (this.erloes_gruppe == '' || this.erloes_gruppe == null) &&
        (this.prov_gruppe == '' || this.prov_gruppe == null) &&
        (this.objekt_typ == '' || this.objekt_typ == null) &&
        (this.objekt_typ_gruppe == '' || this.objekt_typ_gruppe == null)
      ) {
        return
      }
      let artkat = ''
      let arttyp = ''
      let artgrp = ''
      let artkatn = ''
      let arttypn = ''
      let artgrpn = ''

      if (this.art_kategorie != '' && this.art_kategorie != null) {
        artkat = this.art_kategorie.key.lfdnr
        artkatn = this.art_kategorie.description
      }
      if (this.art_type != '' && this.art_type != null) {
        arttyp = this.art_type.key.tpCode
        arttypn = this.art_type.description
      }
      if (this.art_gruppe != '' && this.art_gruppe != null) {
        artgrp = this.art_gruppe.key.lfdnr
        artgrpn = this.art_gruppe.description
      }

      this.filterArticle.push({
        art_kategorie: artkat,
        art_type: arttyp,
        art_gruppe: artgrp
      }),
        this.filterArticleName.push({
          art_kategorie: artkatn,
          art_type: arttypn,
          art_gruppe: artgrpn
        })

      this.art_statisticCode = ''
      this.art_commissionGroup = ''
      this.art_revenueGroup = ''
      this.art_objectType = ''
      this.art_objectTypeGroup = ''

      this.documentTypes()
      this.offerGraph()
    },
    documentGroups() {
      customerDevelopmentService
        .getDocumentsGroups()
        .then(response => {
          console.log('groups')
          console.log(response.data)

          response.data.forEach(data => {
            //this.documentGroupID.push(data.form_gruppe)
            this.documentGroup.push(data)
          })
          console.log('test')
          console.log(this.documentGroup)
        })
        .catch(error => {
          console.error(error)
        })
    },
    articleClose2(index) {
      this.filterArticle.splice(index, 1)
      this.filterArticleName.splice(index, 1)
      this.offerGraph()
    },
    offerGraph() {
      customerDevelopmentService
        .getOffers(
          this.yearStartSelection,
          this.yearEndSelection,
          this.min_netto,
          this.max_netto,
          this.minBetragSelection,
          this.maxBetragSelection,
          this.filterArticle,
          this.selectedDocumentGroup,
          this.statistic_gruppe,
          this.statistic_code,
          this.prov_gruppe,
          this.erloes_gruppe,
          this.objekt_typ,
          this.objekt_typ_gruppe
        )
        .then(response => {
          response.data.forEach(data => {
            this.offerAll.push(data.tCount)
            this.offerOpen.push(data.oCount)
            this.offerDB.push(data.oDb)
            this.offerUM.push(data.oUm)
            this.tDB.push(data.tDb)
            this.tUM.push(data.tUm)
          })
          this.updateChart1()
          this.updateChart2()
        })
        .catch(error => {
          console.error(error)
        })
    },
    updateChart1() {
      const startYear = this.yearStartSelection
      const endYear = this.yearEndSelection
      const xAxisCategories = []

      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString())
      }

      this.offerGraphOptions.xaxis.categories = xAxisCategories

      this.$refs.totalChart1.updateSeries([
        {
          name: 'Anzahl',
          data: this.offerAll
        },
        {
          name: 'Offen',
          data: this.offerOpen
        }
      ]),
        this.$refs.totalChart1.updateOptions({
          xaxis: {
            categories: xAxisCategories
          }
        })
      this.offerAll = []
      this.offerOpen = []
    },
    updateChart2() {
      const startYear = this.yearStartSelection
      const endYear = this.yearEndSelection
      const xAxisCategories = []

      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString())
      }

      this.$refs.totalChart2.updateSeries([
        {
          name: 'Offener Deckungsbetrag',
          group: 'Gesamt',
          data: this.offerDB
        },
        {
          name: 'Offener Umsatz',
          data: this.offerUM
        },
        {
          name: 'Gesamter Deckungsbetrag',
          data: this.tDB
        },
        {
          name: 'Gesamter Umsatz',
          data: this.tUM
        }
      ]),
        this.$refs.totalChart2.updateOptions({
          xaxis: {
            categories: xAxisCategories
          }
        })
      this.tDB = []
      this.tUM = []
      this.offerDB = []
      this.offerUM = []
    }
  }
}
</script>
<style scoped>
.graph-container {
  margin-left: 50%;
}

#graph12 {
  display: flex;
}

#graph1 {
  flex: 1;
}

#graph2 {
  flex: 1;
}

.apexchart {
  margin-left: 50%;
}

.filter-container {
  width: 100%;

  overflow: hidden;
  transition: height 500ms;
}

.filter-container.collapsed .filter-content {
  max-height: 0;
  border: none;
}

.filter-header {
  cursor: pointer;
  padding: 10px;
  background-color: #f0f0f0;
  transition: height 500ms;
}

.filter-content {
  overflow-y: hidden;
  transition: max-height 0.3s ease;
  padding: 10px;
}
</style>
