<template>
  <div class="d-flex justify-end align-center">
    <v-card class="d-flex align-center elevation-1 mx-2 px-2">
      <div class="d-flex align-center ps-2 pe-1">
        <span
          :class="
            (selectedRevenueTypeBool ? '' : 'font-weight-bold ') +
              (showBothRevenueTypes ? 'font-weight-bold' : '')
          "
          >Umsatz</span
        >
        <v-switch
          class="px-2"
          v-model="selectedRevenueTypeBool"
          :disabled="showBothRevenueTypes"
        ></v-switch>
        <span
          :class="
            (selectedRevenueTypeBool ? 'font-weight-bold ' : '') +
              (showBothRevenueTypes ? 'font-weight-bold' : '')
          "
          >Deckungsbeitrag</span
        >
      </div>
      <v-divider vertical class="ps-3"></v-divider>
      <v-checkbox
        v-model="showBothRevenueTypes"
        label="Beides"
        class="px-3"
      ></v-checkbox>
    </v-card>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on" @click.stop.prevent="toggleUntilCurrentMonth">
          <v-icon v-if="getUntilCurrentMonth">mdi-calendar-today</v-icon>
          <v-icon v-else>mdi-calendar-today-outline</v-icon>
        </v-btn>
      </template>
      Bis aktuellen Monat vergleichen
    </v-tooltip>
    <v-btn icon color="black" @click="yearBack">
      <v-icon>mdi-arrow-left-bold-outline</v-icon>
    </v-btn>
    <v-btn icon color="black" @click="yearForward">
      <v-icon>mdi-arrow-right-bold-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
// Vue Store
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'getMinYear',
      'getYearStart',
      'getYearEnd',
      'getUntilCurrentMonth',
      'getSelectedRevenueTypeBool',
      'getShowBothRevenueTypes',
      'getSelectedRevenueTypeShort'
    ]),
    selectedRevenueTypeBool: {
      get() {
        return this.getSelectedRevenueTypeBool
      },
      set(value) {
        this.$store.dispatch('setSelectedRevenueTypeBool', value)

        this.$store.dispatch(
          'setSelectedRevenueTypeShort',
          value ? 'db' : 'net'
        )

        this.$emit('updateCharts')
      }
    },

    showBothRevenueTypes: {
      get() {
        return this.getShowBothRevenueTypes
      },
      set(value) {
        this.$store.dispatch('setShowBothRevenueTypes', value)

        this.$emit('updateCharts')
      }
    }
  },

  methods: {
    yearBack() {
      let yearStart = this.getYearStart
      if (yearStart != this.getMinYear) {
        this.$store.dispatch('setYearStart', yearStart - 1)
        this.$store.dispatch('setYearEnd', this.getYearEnd - 1)
        this.$emit('updateCharts')
      }
    },
    yearForward() {
      let yearEnd = this.getYearEnd
      if (yearEnd != new Date().getFullYear()) {
        this.$store.dispatch('setYearStart', this.getYearStart + 1)
        this.$store.dispatch('setYearEnd', yearEnd + 1)
        this.$emit('updateCharts')
      }
    },

    toggleUntilCurrentMonth() {
      this.$store.dispatch('setUntilCurrentMonth', !this.getUntilCurrentMonth)
      this.$emit('updateCharts')
    }
  }
}
</script>

<style></style>
