<template>
  <v-card>
    <div class="mx-auto mb-12 v-card v-sheet theme--light">
      <div class="mx-4">
        <!-- Filter Panel -->
        <v-expansion-panels class="py-4 mx-auto" v-model="filterPanel">
          <v-expansion-panel>
            <v-expansion-panel-header class="py-0" expand-icon="mdi-menu-down">
              <v-container>
                <v-layout row wrap class="d-flex align-center">
                  <span class="mr-2 pt-1"
                    ><v-icon>mdi-filter-outline</v-icon>Filter</span
                  >
                </v-layout>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="d-flex mx-4 no-gutters">
                <div class="text-h6 font-weight-light d-inline-flex">
                  <v-select
                    class="me-3 fit yearSelect col-3"
                    hint="Anfangsjahr"
                    persistent-hint
                    :items="getYearItems"
                    v-model="yearStart"
                    @change="updateCharts"
                  ></v-select>
                  <v-icon>mdi-minus</v-icon>
                  <v-select
                    class="mx-3 fit yearSelect col-3"
                    hint="Endjahr"
                    persistent-hint
                    :items="getYearItems"
                    v-model="yearEnd"
                    @change="updateCharts"
                  ></v-select>
                </div>
                <v-checkbox
                  v-model="includeCurrentMonth"
                  label="aktuellen Monat inkludieren"
                ></v-checkbox>
              </v-row>
              <v-divider class="mt-6"></v-divider>
              <v-row class="d-flex no-gutters mt-6 mb-2">
                <v-col class="col-break">
                  <FilterSelect
                    label="Filialen"
                    type="shops"
                    @change="updateCharts"
                    :disabled="false"
                  />
                  <FilterSelect
                    label="Adressarten"
                    type="addressTypes"
                    @change="updateCharts"
                    :disabled="false"
                  />
                  <FilterSelect
                    label="Kundengruppen"
                    type="customerGroups"
                    @change="updateCharts"
                    :disabled="false"
                  />
                </v-col>
                <!-- eslint-disable-next-line -->
                <v-divider :vertical=true class="mx-4"></v-divider>
                <v-col class="col-break">
                  <FilterSelect
                    label="Artikeltypen"
                    type="articleTypes"
                    @change="updateCharts"
                    :disabled="false"
                  />
                  <FilterSelect
                    label="Artikelkategorien"
                    type="articleCategories"
                    @change="updateCharts"
                    :disabled="false"
                  />
                  <FilterSelect
                    label="Artikelgruppen"
                    type="articleGroups"
                    @change="updateCharts"
                    :disabled="false"
                  />
                </v-col>
                <!-- eslint-disable-next-line -->
                <v-divider :vertical=true class="mx-4"></v-divider>
                <v-col class="col-break">
                  <FilterSelect
                    label="Statistikgruppen"
                    type="statisticGroups"
                    @change="updateCharts"
                    :disabled="false"
                  />
                  <FilterSelect
                    label="Statistikcode"
                    type="statisticCode"
                    @change="updateCharts"
                    :disabled="true"
                  />
                  <FilterSelect
                    label="Provisionsgruppen"
                    type="commissionGroups"
                    @change="updateCharts"
                    :disabled="true"
                  />
                </v-col>
                <!-- eslint-disable-next-line -->
                <v-divider :vertical=true class="mx-4"></v-divider>
                <v-col class="col-break">
                  <FilterSelect
                    label="Erlösgruppen"
                    type="revenueGroups"
                    @change="updateCharts"
                    :disabled="false"
                  />
                  <FilterSelect
                    label="Aufwandsgruppen"
                    type="expenseGroups"
                    @change="updateCharts"
                    :disabled="false"
                  />
                  <FilterSelect
                    label="Fibukonten"
                    type="fibuAccounts"
                    @change="updateCharts"
                    :disabled="true"
                  />
                </v-col>
              </v-row>
              <v-row class="d-flex justify-end pb-2">
                <v-btn @click.stop.prevent="resetFilter">
                  <v-icon color="error" class="pe-2"> mdi-cancel</v-icon>
                  Alle Filter zurücksetzen
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row>
          <!-- Selected Filters -->
          <v-col class="col-3 mx-auto col-break" id="selectedFilters">
            <v-card>
              <v-card-title>
                <v-col class="col-12">Aktive Filter</v-col>
                <v-select
                  class="me-3 fit yearSelect col-12"
                  hint="Jahr für Filterstatistik"
                  persistent-hint
                  :items="getYearItems"
                  v-model="selectedYear"
                  @change="updateCategoryCharts"
                ></v-select>
                <v-select
                  class="me-3 fit yearSelect col-12"
                  hint="Kategorie für Filterstatistik"
                  persistent-hint
                  :items="getCategorySelection"
                  v-model="selectedCategory"
                  @change="updateCategoryCharts"
                ></v-select>
              </v-card-title>
              <v-card-text>
                <v-list v-if="getSelectedFilters.total">
                  <v-list-item>Keine Filter sind aktiv</v-list-item>
                </v-list>
                <v-list v-else>
                  <v-list-item
                    v-for="(filterType, index) in selectedFilterCategories"
                    two-line
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        getNameForCategory(filterType)
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="wrap-text">
                        <v-chip-group column>
                          <v-chip
                            v-for="(filterItem, index) in selectedFilterNames[
                              filterType
                            ]"
                            :key="index"
                            small
                            close
                            close-icon="mdi-delete"
                            @click="removeFilter(filterType, index)"
                            @click:close="removeFilter(filterType, index)"
                            >{{ filterItem }}</v-chip
                          ></v-chip-group
                        ></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Charts -->
          <v-col class="col-9 col-break">
            <ChartControls @updateCharts="updateCharts" />

            <v-row v-if="!getShowBothRevenueTypes">
              <v-col :cols="12">
                <YearChart
                  ref="yearChart"
                  @updateCharts="updateCharts"
                  :showBothRevenueTypes="false"
                  class="d-flex"
                />
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col :cols="6">
                <YearChart
                  ref="yearChart"
                  @updateCharts="updateCharts"
                  :showBothRevenueTypes="false"
                  class="d-flex"
                />
              </v-col>
              <v-col :cols="6">
                <YearChart
                  ref="yearChartExtra"
                  @updateCharts="updateCharts"
                  :showBothRevenueTypes="true"
                  class="d-flex"
                />
              </v-col>
            </v-row>

            <ChartControls @updateCharts="updateCharts" />

            <v-row v-if="!getShowBothRevenueTypes">
              <v-col :cols="12">
                <MonthChart
                  ref="monthChart"
                  @updateCharts="updateCharts"
                  :showBothRevenueTypes="false"
                  class="d-flex"
                />
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col :cols="6">
                <MonthChart
                  ref="monthChart"
                  @updateCharts="updateCharts"
                  :showBothRevenueTypes="false"
                  class="d-flex"
                />
              </v-col>
              <v-col :cols="6">
                <MonthChart
                  ref="monthChartExtra"
                  @updateCharts="updateCharts"
                  :showBothRevenueTypes="true"
                  class="d-flex"
                />
              </v-col>
            </v-row>

            <v-row v-if="!getShowBothRevenueTypes">
              <v-col :cols="12">
                <CategoryChart
                  ref="categoryChart"
                  @updateCharts="updateCharts"
                  :showBothRevenueTypes="false"
                  class="d-flex"
                />
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col :cols="6">
                <CategoryChart
                  ref="categoryChart"
                  @updateCharts="updateCharts"
                  :showBothRevenueTypes="false"
                  class="d-flex"
                />
              </v-col>
              <v-col :cols="6">
                <CategoryChart
                  ref="categoryChartExtra"
                  @updateCharts="updateCharts"
                  :showBothRevenueTypes="true"
                  class="d-flex"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <ErrorDialog
      :failMessage="getFailMessage"
      :opened="getErrorDialog"
      @closeDialog="$store.dispatch('setErrorDialog', false)"
    />
  </v-card>
</template>

<script>
// Services
import RevenueService from '@/services/statistics/revenueService.js'

// Components
import ErrorDialog from '@/components/core/ErrorDialog.vue'
import FilterSelect from '@/components/statistics/Revenue/FilterSelect.vue'
import ChartControls from '@/components/statistics/Revenue/ChartControls.vue'
import YearChart from '@/components/statistics/Revenue/YearChart.vue'
import MonthChart from '@/components/statistics/Revenue/MonthChart.vue'
import CategoryChart from '@/components/statistics/Revenue/CategoryChart.vue'

// Vue Store
import { mapGetters } from 'vuex'

export default {
  components: {
    ErrorDialog,
    FilterSelect,
    ChartControls,
    YearChart,
    MonthChart,
    CategoryChart
  },

  data() {
    return {
      filterPanel: 0
    }
  },

  mounted() {
    this.init()
  },

  computed: {
    ...mapGetters([
      'getMinYear',
      'getYearItems',
      'getYearStart',
      'getYearEnd',
      'getIncludeCurrentMonth',
      'getShowBothRevenueTypes',
      'getSelectedFilters',
      'getSelectedCategory',
      'getSelectedYear',
      'getFailMessage',
      'getErrorDialog'
    ]),

    yearStart: {
      get() {
        return this.getYearStart
      },
      set(value) {
        this.$store.dispatch('setYearStart', value)
      }
    },

    yearEnd: {
      get() {
        return this.getYearEnd
      },
      set(value) {
        this.$store.dispatch('setYearEnd', value)
      }
    },

    includeCurrentMonth: {
      get() {
        return this.getIncludeCurrentMonth
      },
      set(value) {
        this.$store.dispatch('setIncludeCurrentMonth', value)
      }
    },

    selectedFilterCategories() {
      let selectedFilterCategories = []
      Object.keys(this.getSelectedFilters).forEach((key, index) => {
        if (index != 0 && this.getSelectedFilters[key].length != 0) {
          selectedFilterCategories.push(key)
        }
      })

      return selectedFilterCategories
    },

    selectedFilterNames() {
      const returnObject = {}

      Object.entries(this.getSelectedFilters).forEach(([key, value], index) => {
        if (index !== 0 && value.length !== 0) {
          const categoryData = RevenueService.getDataForCategory(key)

          returnObject[key] = value.map(selectedFilter => {
            return this.$store.state.statisticsRevenueTime[key].find(
              element => element[categoryData.idName] === selectedFilter
            )[categoryData.valueName]
          })
        }
      })
      return returnObject
    },

    selectedYear: {
      get() {
        return this.getSelectedYear
      },
      set(value) {
        this.$store.dispatch('setSelectedYear', value)

        this.updateCategoryCharts()
      }
    },

    selectedCategory: {
      get() {
        return this.getSelectedCategory
      },
      set(value) {
        this.$store.dispatch('setSelectedCategory', value)

        this.updateCategoryCharts()
      }
    },

    getCategorySelection: {
      get() {
        let categorySelectionItems = []

        Object.keys(this.getSelectedFilters).forEach((category, index) => {
          if (index !== 0) {
            categorySelectionItems.push({
              text: RevenueService.getDataForCategory(category).name,
              value: category
            })
          }
        })

        return categorySelectionItems
      }
    }
  },
  methods: {
    init() {
      if (
        this.$route.query.from !== undefined &&
        this.$route.query.to !== undefined
      ) {
        this.$store.dispatch('setYearStart', parseInt(this.$route.query.from))
        this.$store.dispatch('setYearEnd', parseInt(this.$route.query.to))
      } else {
        this.$router.replace({
          query: {
            ...this.$route.query,
            from: this.yearStart,
            to: this.yearEnd
          }
        })
      }

      RevenueService.getMinimumYearForRevenue().then(res => {
        if (res.status === 200) {
          this.$store.dispatch('setMinYear', res.data)

          const items = []
          for (let i = this.getMinYear; i <= new Date().getFullYear(); i++) {
            items.push(i)
          }

          this.$store.dispatch('setYearItems', items)
        } else {
          this.$store.dispatch(
            'setFailMessage',
            'Es trat ein Fehler beim Laden der Statistik auf: minimales Jahr'
          )
          this.$store.dispatch('setErrorDialog', true)
          console.error(res.stack)
        }
      })

      this.$store.dispatch(
        'setSelectedCategory',
        Object.keys(this.getSelectedFilters)[1]
      )

      this.updateCharts()
    },

    resetFilter() {
      this.$store.dispatch('setYearStart', new Date().getFullYear() - 2)
      this.$store.dispatch('setYearEnd', new Date().getFullYear())

      this.$store.dispatch('setIncludeCurrentMonth', true)

      this.$store.dispatch('setSelectedFilters', {
        total: true,
        shops: [],
        addressTypes: [],
        customerGroups: [],
        articleTypes: [],
        articleCategories: [],
        articleGroups: [],
        statisticGroups: [],
        statisticCode: [],
        commissionGroups: [],
        revenueGroups: [],
        expenseGroups: [],
        fibuAccounts: []
      })

      this.$store.dispatch('setSelectedYear', new Date().getFullYear())
      this.$store.dispatch(
        'setSelectedCategory',
        Object.keys(this.getSelectedFilters)[1]
      )

      this.$store.dispatch('setSelectedRevenueTypeBool', false)
      this.$store.dispatch('setSelectedRevenueTypeShort', 'net')
      this.$store.dispatch('setShowBothRevenueTypes', false)

      this.$store.dispatch('setUntilCurrentMonth', false)

      this.updateCharts()
    },

    updateCharts() {
      this.$refs.yearChart.updateRevenueOfYearSpan()
      this.$refs.monthChart.updateShownMonth()
      this.$refs.categoryChart.updateRevenueOfCategory()
      if (this.getShowBothRevenueTypes) {
        this.$refs.yearChartExtra.updateRevenueOfYearSpan()
        this.$refs.monthChartExtra.updateShownMonth()
        this.$refs.categoryChartExtra.updateRevenueOfCategory()
      }
    },

    updateCategoryCharts() {
      this.$refs.categoryChart.updateRevenueOfCategory()
      if (this.getShowBothRevenueTypes) {
        this.$refs.categoryChartExtra.updateRevenueOfCategory()
      }
    },

    removeFilter(filterType, index) {
      const selectedFilters = { ...this.getSelectedFilters }
      let flag = true
      selectedFilters[filterType].splice(index, 1)

      Object.keys(selectedFilters).forEach((key, index) => {
        if (index != 0) {
          if (selectedFilters[key].length > 0) {
            flag = false
          }
        }
      })

      selectedFilters.total = flag

      this.$store.dispatch('setSelectedFilters', selectedFilters)
      this.updateCharts()
    },

    getNameForCategory(filterType) {
      return RevenueService.getDataForCategory(filterType).name
    }
  }
}
</script>

<style scoped>
.v-select__selections {
  font-weight: bold;
  padding-right: 50px;
}

.v-select.fit {
  width: 100px;
}

.v-select.fit .v-select__selection--comma {
  text-overflow: unset;
}

.yearSelect {
  font-weight: bold;
}

@media only screen and (max-width: 599px) {
  .vertical-dividers {
    display: none;
  }

  .col-break {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.wrap-text {
  white-space: normal;
}

#selectedFilters > .v-card {
  display: flex !important;
  flex-direction: column;
}

#selectedFilters > .v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
